.App {
  text-align: center;
  background-color: #f1f1f1;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: 'Arial', sans-serif;
  color: #000;
}

.App-header {
  background-color: #f1f1f1;
  padding: 20px;
}

.App-logo {
  width: 500px;
  max-width: 100%;
  height: auto;
  animation: fadeIn ease 2s;
}

.mission-statement {
  font-size: 1.2em;
  margin: 25px;
  line-height: 1.6;
  text-align: justify;
  border: 2px solid #000;
  padding: 15px;
  border-radius: 10px;
}

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@media screen and (max-width: 600px) {
  .App-logo {
    width: 80%;
  }

  .mission-statement {
    font-family: 'Dancing Script', cursive;
    font-size: 1.2em;
    margin: 25px;
    line-height: 1.6;
    text-align: justify;
    border: 2px solid #000;
    padding: 15px;
    border-radius: 10px;
  }
  
}

.countdown-timer {
  font-family: 'Dancing Script';
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px;
  font-size: 1.5em;
}

.timer-element {
  margin: 0 20px;
}

.timer-element span {
  display: block;
  font-size: 0.6em;
}

.instagram-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.instagram-link {
  text-align: center; 
  color: #E4405F; /* Instagram's brand color */
  text-decoration: none;
  margin-top: 20px;
  display: flex;
  align-items: center;
  font-weight: bold;
  transition: color 0.3s ease-in-out;
}

.instagram-link:hover {
  color: #000; /* Change the color on hover */
}

.fa-instagram {
  margin-right: 10px;
  font-size: 1.5em; /* Make the icon slightly bigger */
}

.follow-text {
  font-family: 'Dancing Script', cursive; /* Handwriting font */
  font-size: 1.2em;
  transition: font-size 0.3s ease-in-out;
}

.instagram-link:hover .follow-text {
  font-size: 1.4em; /* Increase the font size on hover */
}

.pulse-loader {
  display: flex;
  justify-content: center;
  align-items: center;
}

.pulse-dot {
  width: 12px;
  height: 12px;
  margin: 3px;
  background-color: #ff5733;
  border-radius: 50%;
  display: inline-block;
  animation: pulse 1.4s infinite;
}

.pulse-dot:nth-child(2) {
  background-color: #33ff57;
  animation-delay: 0.2s;
}

.pulse-dot:nth-child(3) {
  background-color: #3357ff;
  animation-delay: 0.4s;
}

@keyframes pulse {
  0% { transform: scale(0); }
  100% { transform: scale(1.0); opacity: 0; }
}

.logo-top-left {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 80px; /* adjust as needed */
  transition: all 2s ease-in-out;
  z-index: 1000;
}
