@keyframes fade {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.slideshow-container img {
    animation-name: fade;
    animation-timing-function: ease-in-out;
    animation-duration: 1.5s;
}

.slide-show {
    display: flex;
    align-items: center;
    justify-content: center;
}

.slide-container {
    width: 300px;
    height: 300px;
    overflow: hidden;
    position: relative;
    transition: opacity 1.5s ease-in-out;
}

.slide-image {
    max-width: 100%;
    max-height: 100%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}